<template>
    <div>
        <div class="row">
            <div class="col-xxl-12">
                <b-jumbotron
                    :header="`Espace ${role === 'Gestionnaire' ? 'Formation' : 'Stagiaire'}`"
                    :lead="`Bienvenue sur votre espace ${role === 'Gestionnaire' ? 'formation' : 'stagiaire'} ${currentUser.firstname} ${currentUser.lastname}`"
                    class="mb-0"
                >
                </b-jumbotron>
            </div>
        </div>
        <div v-if="role === 'Gestionnaire'" :class="isMobile() ? '' : 'row'">
            <div :class="isMobile() ? 'row mt-8' : 'col-xxl-6 mt-8'">
                <div :class="isMobile() ? 'col-xxl-12' : ''">
                    <div class="card card-custom bg-radial-gradient-primary card-stretch custom">
                        <div class="card-header border-0 py-5">
                            <h3 class="card-title font-weight-bolder text-white">{{ $t('INVOICE.CHART.TITLE') }}</h3>
                        </div>
                        <div class="card-body d-flex flex-column p-0">
                            <InvoiceChart/>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="isMobile() ? 'row mt-8' : 'col-xxl-6 mt-8'">
                <div :class="isMobile() ? 'col-xxl-12' : ''">
                    <div class="card card-custom bg-radial-gradient-success card-stretch custom">
                        <div class="card-header border-0 py-5">
                            <h3 class="card-title font-weight-bolder text-white">{{ $t('ACTION.CHART.TITLE') }}</h3>
                        </div>
                        <div class="card-body d-flex flex-column p-0">
                            <ActionChart/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="role === 'Gestionnaire'" class="row mt-8">
            <div class="col-xxl-12">
                <div class="card card-custom bg-radial-gradient-info card-stretch custom">
                    <div class="card-header border-0 py-5">
                        <h3 class="card-title font-weight-bolder text-white">{{ $t('INTERN.CHART.TITLE') }}</h3>
                    </div>
                    <div class="card-body d-flex flex-column p-0">
                        <InternThemeChart/>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="
                (tauxFormationChart) && (role === 'Gestionnaire' && currentUser.tauxEtSeuil === 1) ||
                    currentUser.role === 'Administrateur' ||
                    currentUser.role === 'Super Admin'
            " class="row mt-8">
            <div class="col-xxl-12">
                <div class="card card-custom card-stretch custom">
                    <div class="card-header border-0 py-5">
                        <h3 class="card-title font-weight-bolder">{{ $t('INTERN.CHARTTAUXFORMATION.TITLE') }}</h3>
                    </div>
                    <div class="card-body d-flex flex-column p-0">
                        <TauxFormationChart @showTauxFormationChart="tauxFormationChart = false"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {SET_PAGETITLE} from '@/core/services/store/pagetitle.module'
import InvoiceChart from '@/view/pages/invoice/widget/Chart'
import ActionChart from '@/view/pages/action/widget/Chart'
import InternThemeChart from '@/view/pages/intern/widget/ChartPerYear.vue'
import TauxFormationChart from '@/view/pages/intern/widget/TauxFormationChart.vue'

export default {
    components: {
        InvoiceChart,
        ActionChart,
        InternThemeChart,
        TauxFormationChart
    },
    mounted() {
        this.$store.dispatch(SET_PAGETITLE, this.$t('MENU.HOME'))
    },
    computed: {
        ...mapGetters({
            currentUser: 'currentUser'
        })
    },
    data() {
        return {
            role: this.$store.getters.getRole.name,
            tauxFormationChart: true
        }
    }
}
</script>
<style>
.custom {
    height: 84%;
}
</style>